import Axios from 'axios'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useCallback} from 'react'

import styles from './ByType.module.css'

import {API_URL} from '../../ApiUrl.js'
import CardGroup from 'reactstrap/lib/CardGroup'
import Card from 'reactstrap/lib/Card'

function ByType() {
  let {typeId} = useParams()
  const [arts, setArts] = useState([])
  const [error, setError] = useState('')
  const [type, setType] = useState('')

  const getArtsByType = useCallback(async () => {
    try {
      const res = await Axios.get(`${API_URL}/api/sculptures`)
      const artsByType = res.data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((item) => item.TypeId === typeId)
      setType(artsByType[0].Type.label)
      const array = new Set()
      const filteredArr = artsByType.filter((item) => {
        const duplicate = array.has(item.Category.id)
        array.add(item.Category.id)
        return !duplicate
      })
      return setArts(filteredArr)
    } catch (err) {
      setError(err)
    }
  }, [typeId])

  useEffect(() => {
    getArtsByType()
  }, [getArtsByType])

  return (
    <div className={styles.byTypeStyle}>
      {error ? (
        <p style={{color: 'red'}}>
          Une erreur est apparue lors du chargement des oeuvres
        </p>
      ) : (
        ''
      )}
      <h1 className="title-centered">{type}</h1>
      <div className="row-flex-spaced">
        <CardGroup className={styles.cardGroup}>
          {arts.map((item) => {
            if (arts.length <= 1) {
              return (
                <div
                  key={item.id}
                  className={`${styles.cardSizeType} col-flex`}
                >
                  <Link to={`/art/${item.id}`}>
                    <img
                      className={styles.imageType}
                      src={item.imageUrl}
                      alt=""
                    />
                  </Link>
                </div>
              )
            } else if (item.Category !== null && item.Category !== undefined) {
              return (
                <div key={item.id} className={`${styles.cardSizeCat} col-flex`}>
                  <Link to={`/galery/${item.Type.id}/${item.Category.id}`}>
                    <img
                      className={styles.imageCat}
                      src={item.imageUrl}
                      alt=""
                    />
                    <h3 className="title-black-centered">
                      {item.Category.label}
                    </h3>
                  </Link>
                </div>
              )
            } else {
              return (
                <Card key={item.id} className="card-art">
                  <Link to={`/art/${item.id}`}>
                    <img src={item.imageUrl} alt="" />
                  </Link>
                </Card>
              )
            }
          })}
        </CardGroup>
      </div>
    </div>
  )
}
export default ByType
