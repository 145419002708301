import React from 'react'
import {Row, Col} from 'reactstrap'

import EventsInComing from './EventsInComing'
import styles from './HomePage.module.css'
import ickasso from '../img/ickasso.jpg'
import Galery from '../Galerie/Galery'

function HomePage(props) {
  return (
    <div>
      <section className={styles.homePageSection}>
        <Row className={`${styles.firstRow} row`}>
          <Col
            xl={{size: '4', offset: '0'}}
            lg={{size: '4', offset: '0'}}
            md={{size: '9', offset: '1'}}
            xs={{size: '10', offset: '1'}}
            className={styles.imgPresMobile}
          >
            <img
              src={ickasso}
              alt="Isabelle Conrozier Kassovic"
              className={styles.imgPres}
            />
          </Col>
          <Col
            xl={{size: '4', offset: '2'}}
            lg={{size: '6', offset: '1'}}
            md={{size: '11', offset: '0'}}
            sm={{size: '9', offset: '1'}}
            xs={{size: '10', offset: '1'}}
            className={styles.firstParagraphMobile}
          >
            <h1 className="first-title">IcKasso</h1>
            <p>
              À travers ses sculptures peintes, {''}
              <b>Isabelle Conrozier Kassovic </b> nous invite à la suivre dans
              son univers coloré où amour rime avec humour.
            </p>
            <p>
              Ses oeuvres en terres cuites, toutes uniques, sont réalisées dans
              son atelier au Rouret (Alpes-Maritimes).
              <br />
              <b>Les Demoiselles d&apos;Isabelle</b> {''}, scuptures féminines
              stylisées peintes à l'acrylique, sont sa signature artistique.
              <br />
              <b>Les Classics Kassovic</b> {''}, sculptures féminines classiques
              en bronze, affichent leur sensualité avec sérénité..
            </p>
          </Col>
        </Row>
        <Row className={`${styles.secondRow} row`}>
          <Col
            className={`${styles.imageText} col-flex`}
            xl={{size: '10', offset: '0'}}
            lg={{size: '11', offset: '0'}}
            md={{size: '12', offset: '0'}}
            sm={{size: '9', offset: '1'}}
            xs={{size: '10', offset: '1'}}
          >
            <p>
              Née en 1959 aux États-Unis, d'un père slave et d'une mère
              française, {''}
              <b>Isabelle Conrozier Kassovic</b> est titulaire d'un CAPES et
              d'une maitrise de Mathématiques, Isabelle est mariée et mère de
              trois enfants, nés à Singapour, au Vietnam et en France.
            </p>
            <p>
              En 1989-91, Isabelle a suivi une formation d'arts plastiques à{' '}
              {''}
              <b> l'Université de Penn Valley</b> à Kansas City (Missouri). Elle
              a ensuite vécu à Singapour (1992-96), où elle s'est spécialisée
              dans la sculpture en terre cuite dans {''}
              <b>l'atelier de Lim Meng Khuong</b>, où elle a acquis une maitrise
              du nu académique.
            </p>
            <p>
              Installée dans le midi de la France depuis 1998, Isabelle crée son
              propre atelier et commence à exposer en 2002.
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Galery />
      </section>
      <section className={styles.eventsInComingSection}>
        <EventsInComing />
      </section>
    </div>
  )
}

export default HomePage
