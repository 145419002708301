import Axios from 'axios'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {Link} from 'react-router-dom'

import styles from './Galery.module.css'

import {API_URL} from '../../ApiUrl.js'
import CardGroup from 'reactstrap/lib/CardGroup'
import Card from 'reactstrap/lib/Card'
import sculptures from '../img/sculptures.jpeg'
import peintures from '../img/peintures.jpeg'

function Galery() {
  const [arts, setArts] = useState([])
  const [error, setError] = useState('')

  const getArts = async () => {
    try {
      const res = await Axios.get(`${API_URL}/api/sculptures`)
      const array = res.data
        .sort((a, b) => b.Type.label > a.Type.label)
        .reduce((acc, current) => {
          const type = acc.find((item) => item.Type.id === current.Type.id)
          if (!type) {
            return acc.concat([current])
          } else {
            return acc
          }
        }, [])
        console.log(array)
      setArts(array)
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    getArts()
  }, [])

  return (
    <div className={styles.galeryStyle}>
      {error ? (
        <p style={{color: 'red'}}>
          Une erreur est apparue lors du chargement des oeuvres
        </p>
      ) : (
        ''
      )}
      <h1 className="title-centered-white">Galerie</h1>
      <div className="row-flex-spaced">
        <CardGroup className={styles.cardGroup}>
          {arts.map((item, i) =>
            arts.length <= 1 ? (
              <div key={item.id} className={styles.cardSizeType}>
                <Link to={`/galery/${item.Type.id}`}>
                  <h3 className={styles.h3Card}>{item.Type.label}</h3>
                  <img
                    className={styles.imageType}
                    src={item.imageUrl}
                    alt=""
                  />
                </Link>
              </div>
            ) : arts[i].Type.label === 'Sculptures' ? (
              <Card key={item.id} className="card-galery-vertical">
                <Link to={`/galery/${item.Type.id}`}>
                  <h3>{item.Type.label}</h3>
                  <img src={sculptures} alt="" />
                </Link>
              </Card>
            ) : arts[i].Type.label === 'Peintures' ? (
              <Card key={item.id} className="card-galery">
                <Link to={`/galery/${item.Type.id}`}>
                  <h3>{item.Type.label}</h3>
                  <img src={peintures} alt="" />
                </Link>
              </Card>
            ) : (
              <Card key={item.id} className="card-galery">
                <Link to={`/galery/${item.Type.id}`}>
                  <h3>{item.Type.label}</h3>
                  <img src={item.imageUrl} alt="" />
                </Link>
              </Card>
            )
          )}
        </CardGroup>
      </div>
    </div>
  )
}
export default Galery
