import React, {useCallback, useEffect, useState} from 'react'
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import {Row, Col} from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import ImageGallery from 'react-image-gallery'
import ickasso from '../img/ickasso.jpg'

import {API_URL} from '../../ApiUrl.js'
import styles from './SingleEvent.module.css'

function SingleEvent() {
  let {eventId} = useParams()
  const [event, setEvent] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const images = [
    {
      original: `${event.photoURL}`,
      thumbnail: `${event.photoURL}`,
    },
    {
      original: `${event.photoEventOne ? event.photoEventOne : ickasso}`,
      thumbnail: `${event.photoEventOne ? event.photoEventOne : ickasso}`,
    },
    {
      original: `${event.photoEventTwo ? event.photoEventTwo : ickasso}`,
      thumbnail: `${event.photoEventTwo ? event.photoEventTwo : ickasso}`,
    },
    {
      original: `${event.photoEventThree ? event.photoEventThree : ickasso}`,
      thumbnail: `${event.photoEventThree ? event.photoEventThree : ickasso}`,
    },
    {
      original: `${event.photoEventFour ? event.photoEventFour : ickasso}`,
      thumbnail: `${event.photoEventFour ? event.photoEventFour : ickasso}`,
    },
  ]
  const thumbnailPosition = 'left'
  const showPlayButton = false

  const getEvents = useCallback(async () => {
    try {
      setLoading(true)
      const res = await Axios.get(`${API_URL}/api/event`)
      const eventData = res.data.find((item) => item.id === eventId)
      setEvent(eventData)
    } catch (err) {
      setError(err)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [eventId])

  useEffect(() => {
    getEvents()
  }, [getEvents])

  return (
    <div className={styles.eventSinglePage}>
      {loading ? <p style={{color: 'orange'}}>...Chargement...</p> : ''}
      {error ? (
        <p style={{color: 'red'}}>Something bad happened please reload page</p>
      ) : (
        ''
      )}
      <h4 className={styles.title}>{event.title}</h4>
      <Row>
        <Col lg={{size: '10', offset: '0'}} sm={{size: '11', offset: '0'}} xs={{size: '10', offset: '1'}}>
          <div className={styles.carousselEvent}>
            <ImageGallery
              items={images}
              thumbnailPosition={thumbnailPosition}
              showPlayButton={showPlayButton}
              disableSwipe={false}
            ></ImageGallery>
          </div>
          <div className="text-single-event">
            {ReactHtmlParser(event.content)}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SingleEvent
