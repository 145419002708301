import React, {useCallback, useEffect, useState} from 'react'
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import {Row, Col} from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'

import {API_URL} from '../../ApiUrl.js'
import styles from './SingleArt.module.css'

function SingleArt() {
  let {id} = useParams()
  const [art, setArt] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')

  const getArts = useCallback(async () => {
    try {
      setLoading(true)
      const res = await Axios.get(`${API_URL}/api/sculptures`)
      const artId = res.data.find((item) => item.id === id)
      const typeLabel = artId.Type.label
      setType(typeLabel)
      setArt(artId)
      setLoading(false)
    } catch (err) {
      setError(err)
    }
  }, [id])

  useEffect(() => {
    getArts()
  }, [getArts])

  return (
    <div className={styles.artSinglePage}>
      {loading ? <p style={{color: 'orange'}}>...Chargement...</p> : ''}
      {error ? (
        <p style={{color: 'red'}}>Something bad happened please reload page</p>
      ) : (
        ''
      )}
      <h4 className="type">{type}</h4>
      <h3 className="title-art">{art.title}</h3>
      <Row className="margin-up">
        <Col lg={{size: '5', offset: '0'}} sm={{size: '6', offset: '0'}} xs={{size: '6', offset: '0'}} className={`${styles.imageDiv} col`}>
          <img src={art.imageUrl} alt="photoUrl" />
        </Col>
        <Col lg={{size: '4', offset: '1'}} sm={{size: '4', offset: '1'}} xs={{size: '5', offset: '1'}}>
          <div className="box">
            <div className="text-single-art col-flex">
              {ReactHtmlParser(art.description)}
              <p className="margin-0">{art.price} €</p>
            </div>
            {art.command ? (
              <div className="text-single-art">
                <b>&laquo; {art.command} &raquo;</b>
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SingleArt
