import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Error404.module.css'

function Error404() {
  return (
    <div className={styles.errorStyle}>
      <h4 className="title-centered">Oops !</h4>
      <p className="error-text">La page demandée n&apos;existe pas...</p>
      <Link to="/" className="back">
        <p className="p-link">
          <span className="pic arrow-right"></span>
          Retour à l&apos;accueil
          <span className="pic arrow-left"></span>
        </p>
      </Link>
    </div>
  )
}

export default Error404
