import React from 'react'
import Axios from 'axios'
import {useEffect} from 'react'
import {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useCallback} from 'react'

import styles from './ByCat.module.css'

import {API_URL} from '../../ApiUrl.js'
import CardGroup from 'reactstrap/lib/CardGroup'

function ByCat() {
  let {catId, typeId} = useParams()
  const [arts, setArts] = useState([])
  const [artsWithSc, setArtsWithSc] = useState([])
  const [isArtsSc, setIsArtsSc] = useState(false)
  const [error, setError] = useState('')
  const [cat, setCat] = useState('')
  const [type, setType] = useState('')

  const getArtsByCat = useCallback(async () => {
    try {
      const res = await Axios.get(`${API_URL}/api/sculptures`)
      const artsByCat = res.data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((item) => item.CategoryId === catId && item.TypeId === typeId)
      setType(artsByCat[0].Type.label)
      setCat(artsByCat[0].Category.label)
      const subCat = artsByCat.filter(
        (item) =>
          item.SousCategory !== null &&
          item.SousCategory !== {} &&
          item.SousCategory !== undefined
      )
      subCat.filter((item) => {
        const array = [subCat[0]]
        subCat.find((item) =>
          item.SousCategoryId !== array[0].SousCategoryId
            ? array.push(item)
            : ''
        )
        setIsArtsSc(true)
        return setArtsWithSc(array)
      })
      setArts(artsByCat)
    } catch (err) {
      setError(err)
      console.log(err)
    }
  }, [catId, typeId])

  useEffect(() => {
    getArtsByCat()
  }, [getArtsByCat])

  return (
    <div className={styles.byCatStyle}>
      {error ? (
        <p style={{color: 'red'}}>
          Une erreur est apparue lors du chargement des oeuvres...
        </p>
      ) : (
        ''
      )}
      <h3 className="title-centered">{type}</h3>
      <h2 className="title-centered">{cat}</h2>
      <CardGroup className={styles.cardGroup}>
        {isArtsSc
          ? artsWithSc.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`${styles.cardSizeSubCat} col-flex`}
                >
                  <Link
                    to={`/galery/${item.Type.id}/${item.Category.id}/sc/${item.SousCategory.id}`}
                  >
                    <img
                      className={styles.imageSubCat}
                      src={item.imageUrl}
                      alt=""
                    />
                    <h3 className="title-black-centered">
                      {item.SousCategory.label}
                    </h3>
                  </Link>
                </div>
              )
            })
          : arts.map((item) => {
              if (arts.length <= 1) {
                return (
                  <div
                    key={item.id}
                    className={`${styles.cardSizeCat} col-flex`}
                  >
                    <Link to={`/galery/${typeId}/${item.id}`}>
                      <img
                        className={styles.imageCat}
                        src={item.imageUrl}
                        alt=""
                      />
                    </Link>
                  </div>
                )
              } else {
                return (
                  <div key={item.id} className="card-art">
                    <Link
                      to={`/galery/${item.Type.id}/${item.Category.id}/${item.id}`}
                    >
                      <img src={item.imageUrl} alt="" />
                    </Link>
                  </div>
                )
              }
            })}
      </CardGroup>
    </div>
  )
}
export default ByCat
