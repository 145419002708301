import React from 'react'
import {Row, Col, Card, CardBody, CardTitle, CardText} from 'reactstrap'
import {useState} from 'react'
import Axios from 'axios'
import {useEffect} from 'react'
import ReactHtmlParser from 'react-html-parser'

import styles from './EventsInComing.module.css'
import {API_URL} from '../../ApiUrl.js'

function EventsInComing() {
  const [loading, setLoading] = useState(false)
  const [event, setEvent] = useState([])
  const [error, setError] = useState('')

  const getEvent = async () => {
    try {
      setLoading(true)
      const res = await Axios.get(`${API_URL}/api/event`)
      const getFavorite = res.data.filter((item) => item.favorite === true)
      console.log(getFavorite)
      setEvent(getFavorite)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEvent()
  }, [])

  return (
    <Row className={styles.eventsInComing}>
      <Col>
        <h1>
          Évènements <br /> à venir
        </h1>
        {loading ? <p style={{color: 'orange'}}>...Chargement...</p> : ''}
        {error ? (
          <p style={{color: 'red'}}>
            Une erreur est apparue lors du chargement des évènements...
          </p>
        ) : (
          ''
        )}
        {event.map((item) => {
          const dateStart = new Date(item.dateStartEvent)
          const dateEnd = new Date(item.dateEndEvent)
          return (
            <Card key={item.id} className="card-event-coming">
              <CardBody className="card-body-event-coming">
                <CardTitle>
                  <h3 className="titleEventComing">{item.title}</h3>
                </CardTitle>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <CardText>
                      {item.dateStartEvent && item.dateEndEvent ? (
                        <p className="text-event-coming">
                          Du{' '}
                          {dateStart.toLocaleDateString('fr-FR', {
                            day: 'numeric',
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                          })}{' '}
                          au{' '}
                          {dateEnd.toLocaleDateString('fr-FR', {
                            day: 'numeric',
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                          })}
                        </p>
                      ) : item.dateStartEvent ? (
                        <p className="text-event-coming">
                          Le
                          {dateStart.toLocaleDateString('fr-FR', {
                            day: 'numeric',
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                          })}
                        </p>
                      ) : (
                        ''
                      )}
                    </CardText>
                    <CardText>
                      <p className="text-event-coming">
                        Lieu de l&apos;évènement : {item.localisation}
                      </p>
                    </CardText>
                    <CardText>
                      <p className="react-html-parser">
                        {ReactHtmlParser(item.content)}
                      </p>
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )
        })}
        <div className="cta">
          <a href="/events" className="event-link">
            Autres Évènements
          </a>
        </div>
      </Col>
    </Row>
  )
}

export default EventsInComing
