import React, {useState} from 'react'
import Axios from 'axios'
import {Form, Input, Row, Col, Button} from 'reactstrap'

import styles from './Contact.module.css'
import ContactMe from './ContactMe'

import {API_URL} from '../../ApiUrl.js'

function ContactUs() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [send, setSend] = useState(false)
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const handleChange = (e) => {
    if (e.target.id === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.id === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.id === 'email') {
      setEmail(e.target.value)
    } else if (e.target.id === 'subject') {
      setSubject(e.target.value)
    } else if (e.target.id === 'message') {
      setMessage(e.target.value)
    }
  }
  const sendMessage = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await Axios.post(`${API_URL}/api/contact`, {
        lastName,
        firstName,
        email,
        subject,
        message,
      })
      setSend(true)
    } catch (err) {
      setError(err)
    } finally {
      setLastName('')
      setFirstName('')
      setEmail('')
      setSubject('')
      setMessage('')
      setLoading(false)
    }
  }
  return (
    <div className={styles.contactStyle}>
      <h1 className="title-centered-no-margin-bottom">Contact</h1>
      <ContactMe />
      <Row>
        <Col
          lg={{size: '7', offset: '3'}}
          md={{size: '10', offset: '1'}}
          sm={{size: '7', offset: '2'}}
          xs={{size: '10', offset: '1'}}
        >
          <Form onSubmit={sendMessage} className={styles.formStyle}>
            <Row form>
              <Col style={{padding: 0}} lg="5" md={{size: '4', offset: '0'}}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Nom"
                  value={lastName}
                  onChange={handleChange}
                  required
                  className="input-mail-top-left"
                />
              </Col>
              <Col style={{padding: 0}} lg="5" md={{size: '4', offset: '0'}}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Prénom"
                  value={firstName}
                  onChange={handleChange}
                  required
                  className="input-mail-top"
                />
              </Col>
            </Row>
            <Row form>
              <Col style={{padding: 0}} lg="10" md={{size: '4', offset: '0'}}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                  required
                  className="input-mail-center-top"
                />
              </Col>
            </Row>
            <Row form>
              <Col style={{padding: 0}} lg="10" md={{size: '4', offset: '0'}}>
                <Input
                  name="subject"
                  type="text"
                  id="subject"
                  placeholder="Sujet"
                  value={subject}
                  onChange={handleChange}
                  required
                  className="input-mail-center"
                />
              </Col>
            </Row>

            <Row form>
              <Col
                style={{padding: 0}}
                lg={{size: '10', offset: '0'}}
                md={{size: '10', offset: '0'}}
              >
                <Input
                  type="textarea"
                  name="textarea"
                  id="message"
                  placeholder="Message"
                  value={message}
                  onChange={handleChange}
                  required
                  className="input-text-area"
                />
              </Col>
            </Row>
            {error ? (
              <Row form>
                <Col md={{size: '10', offset: '0'}}>
                  <p style={{color: 'red'}}>
                    Une erreur est apparue lors de l'envoie de votre message...
                    Veuillez réessayez...
                  </p>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {send ? (
              <Row form>
                <Col md={{size: '10', offset: '0'}}>
                  <p style={{color: 'green'}}>
                    Votre message a bien été envoyé. Merci !
                  </p>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {loading ? (
              <Row form>
                <Col md={{size: '10', offset: '0'}}>
                  <p style={{color: 'orange'}}>
                    Chargement ... Veuillez patientez s&apos;il vous plaît ...
                  </p>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row className={styles.rowMargin} form>
              <Col md={{size: '5', offset: '0'}}>
                <Button className="submit-button" type="submit">
                  Envoyer
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
export default ContactUs
