import Axios from 'axios'
import React, {useState} from 'react'
import {useCallback} from 'react'
import {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Card} from 'reactstrap'

import styles from './BySubCat.module.css'

import {API_URL} from '../../ApiUrl.js'
import CardGroup from 'reactstrap/lib/CardGroup'

function BySubCat() {
  let {typeId, catId, subCatId} = useParams()
  const [arts, setArts] = useState([])
  const [error, setError] = useState('')
  const [cat, setCat] = useState('')
  const [type, setType] = useState('')
  const [subCat, setSubCat] = useState('')

  const getArtsBySubCat = useCallback(async () => {
    try {
      const res = await Axios.get(`${API_URL}/api/sculptures`)
      const artsBySubCat = res.data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(
        (item) =>
          item.SousCategoryId === subCatId &&
          item.CategoryId === catId &&
          item.TypeId === typeId
      )
      setType(artsBySubCat[0].Type.label)
      setCat(artsBySubCat[0].Category.label)
      setSubCat(artsBySubCat[0].SousCategory.label)
      setArts(artsBySubCat)
    } catch (err) {
      setError(err)
    }
  }, [catId, subCatId, typeId])

  useEffect(() => {
    getArtsBySubCat()
  }, [getArtsBySubCat])

  return (
    <div className={styles.bySubCatStyle}>
      {error ? (
        <p style={{color: 'red'}}>
          Une erreur est apparue lors du chargement des oeuvres
        </p>
      ) : (
        ''
      )}
      <h3 className="title-centered">{type}</h3>
      <h2 className="title-centered">{cat}</h2>
      <h1 className="title-centered-no-margin-top">{subCat}</h1>
      <CardGroup className={styles.cardGroup}>
        {arts.map((item) => {
          if (arts.length <= 1) {
            return (
              <div
                key={item.id}
                className={`${styles.cardSizeSubCat} col-flex`}
              >
                <Link to={`/galery/${typeId}/${catId}/sc/${subCatId}/${item.id}`}>
                  <img
                    className={styles.imageSubCat}
                    src={item.imageUrl}
                    alt=""
                  />
                </Link>
              </div>
            )
          } else {
            return (
              <Card key={item.id} className="card-art">
                <Link to={`/galery/${typeId}/${catId}/sc/${subCatId}/${item.id}`}>
                  <img src={item.imageUrl} alt="" />
                </Link>
              </Card>
            )
          }
        })}
      </CardGroup>
    </div>
  )
}
export default BySubCat
