import React from 'react'

function ContactMe() {
  return (
    <div className="col-flex-centered margin-bot">
      <div className="row-flex-left">
        <p className="contact-text">Isabelle Conrozier Kassovic</p>
      </div>
      <div className="row-flex-left">
        <p className="contact-text"> Téléphone : 06.84.98.80.78</p>
      </div>
      <div className="row-flex-left">
        <p className="contact-text"> Email :</p>
        <a className="mail" href="mailto:i.conrozier@orange.fr">
          i.conrozier@orange.fr
        </a>
      </div>
    </div>
  )
}
export default ContactMe
