import React from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap'
import {useState} from 'react'
import Axios from 'axios'
import {useEffect} from 'react'
import ReactHtmlParser from 'react-html-parser'

import styles from './Events.module.css'

import {API_URL} from '../../ApiUrl.js'
import CardGroup from 'reactstrap/lib/CardGroup'
import {Link} from 'react-router-dom'

function Events() {
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [error, setError] = useState('')

  const getEvents = async () => {
    try {
      setLoading(true)
      const res = await Axios.get(`${API_URL}/api/event`)
      const sortEventsbyFav = res.data.sort((a, b) => {
        return b.favorite - a.favorite
      })
      setEvents(sortEventsbyFav)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <div className={styles.eventPage}>
      {loading ? <p style={{color: 'orange'}}>...Chargement...</p> : ''}
      {error ? (
        <p style={{color: 'red'}}>Something bad happened please reload page</p>
      ) : (
        ''
      )}
      <h1 className="title-centered">Évènements à venir</h1>
      {events
        .filter((item) => {
          return item.favorite === true
        })
        .map((item) => {
          const dateStart = new Date(item.dateStartEvent)
          const dateEnd = new Date(item.dateEndEvent)
          return (
            <>
              <Card className="card-event" key={item.id}>
                <CardBody>
                  <div className={styles.headerCard}>
                    <CardTitle>
                      <h3 className={`${styles.headerTitle} title-black`}>
                        {item.title}
                      </h3>
                    </CardTitle>
                    <img
                      src={item.photoURL}
                      alt=""
                      className={styles.imageEvent}
                    />
                  </div>

                  <Row>
                    <Col xl="12" lg="12" md="12" xs={{size: '12', offset: '0'}}>
                      <CardText className="text-event">
                        Lieu de l&apos;évènement : {item.localisation}
                      </CardText>
                      <CardText className="text-event">
                        {item.dateStartEvent && item.dateEndEvent ? (
                          <>
                            Du{' '}
                            {dateStart.toLocaleDateString('fr-FR', {
                              day: 'numeric',
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                            })}{' '}
                            au{' '}
                            {dateEnd.toLocaleDateString('fr-FR', {
                              day: 'numeric',
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                            })}
                          </>
                        ) : item.dateStartEvent ? (
                          <>
                            Le{' '}
                            {dateStart.toLocaleDateString('fr-FR', {
                              day: 'numeric',
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                            })}
                          </>
                        ) : (
                          ''
                        )}
                      </CardText>
                      <CardText className="text-event">
                        {item.timeStartEvent && item.timeEndEvent ? (
                          <>
                            De {item.timeStartEvent} à {item.timeEndEvent}{' '}
                          </>
                        ) : item.timeStartEvent ? (
                          <> À Partir de {item.timeStartEvent}</>
                        ) : (
                          ''
                        )}
                      </CardText>
                      <br />
                      <CardText className="text-event">
                        {ReactHtmlParser(item.content)}
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )
        })}
      <h1 className="title-centered">Autres évènements</h1>
      <CardGroup className={styles.cardGroup}>
        {events
          .sort((a, b) => {
            const dateA = new Date(a.dateStartEvent)
            const dateB = new Date(b.dateStartEvent)
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
          })
          .map((item) => {
            if (item.favorite === false) {
              if (
                item.photoEventOne &&
                item.photoEventTwo &&
                item.photoEventThree &&
                item.photoEventFour
              ) {
                return (
                  <Card key={item.id} className="card-event-past">
                    <CardBody className="card-body-event-past">
                      <CardTitle className="card-title-event-past">
                        <h3 className="title-black">{item.title}</h3>
                      </CardTitle>
                      <div className="card-body-height">
                        <div className="row-flex-between">
                          <div className="event-img">
                            {item.photoEventOne ? (
                              <CardImg
                                src={item.photoEventOne}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="event-img">
                            {item.photoEventTwo ? (
                              <CardImg
                                src={item.photoEventTwo}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="row-flex-between">
                          <div className="event-img">
                            {item.photoEventThree ? (
                              <CardImg
                                src={item.photoEventThree}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="event-img">
                            {item.photoEventFour ? (
                              <CardImg
                                src={item.photoEventFour}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      <CardText className="text-event-past">
                        {ReactHtmlParser(item.content)}
                      </CardText>

                      <div className="cta-see-more">
                        <Link to={`/events/${item.id}`} className="see-more">
                          Voir plus
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                )
              }
              if (
                (item.content ||
                  item.content !== undefined ||
                  item.content !== '' ||
                  item.content !== null) &&
                (item.photoEventOne ||
                  item.photoEventTwo ||
                  item.photoEventThree ||
                  item.photoEventFour)
              ) {
                return (
                  <Card key={item.id} className="card-event-past">
                    <CardBody className="card-body-event-past">
                      <CardTitle className="card-title-event-past">
                        <h3 className="title-black">{item.title}</h3>
                      </CardTitle>
                      <div className="card-body-height">
                        <div className="row-flex-between">
                          <div className="event-img">
                            {item.photoEventOne ? (
                              <CardImg
                                src={item.photoEventOne}
                                alt={item.title}
                              />
                            ) : (
                              <CardImg src={item.photoURL} alt={item.title} />
                            )}
                          </div>
                          <div className="event-img">
                            {item.photoEventTwo ? (
                              <CardImg
                                src={item.photoEventTwo}
                                alt={item.title}
                              />
                            ) : (
                              <CardImg src={item.photoURL} alt={item.title} />
                            )}
                          </div>
                        </div>
                        <div className="row-flex-between">
                          <div className="event-img">
                            {item.photoEventThree ? (
                              <CardImg
                                src={item.photoEventThree}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="event-img">
                            {item.photoEventFour ? (
                              <CardImg
                                src={item.photoEventFour}
                                alt={item.title}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      <CardText className="text-event-past">
                        {ReactHtmlParser(item.content)}
                      </CardText>

                      <div className="cta-see-more">
                        <Link to={`/events/${item.id}`} className="see-more">
                          Voir plus
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                )
              }
            }
            return ''
          })}
      </CardGroup>
    </div>
  )
}

export default Events
