import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import HomePage from './Home/HomePage'
import MyNavbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import ContactUs from './Contact/Contact'
import Events from './Events/Events'
import BySubCat from './Galerie/BySubCat'
import ByCat from './Galerie/ByCat'
import ByType from './Galerie/ByType'
import SingleEvent from './Events/SingleEvent'
import SingleArt from './Galerie/SingleArt'
import Error404 from "./Error/Error404"
import Galery from './Galerie/Galery'

function Router() {
  return (
    <BrowserRouter>
      <MyNavbar />
      <Switch>
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:eventId" component={SingleEvent} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/art/:id" component={SingleArt} />
        <Route exact path="/galery" component={Galery} />
        <Route exact path="/galery/:typeId/:catId/:id" component={SingleArt} />
        <Route exact path="/galery/:typeId/:catId" component={ByCat} />
        <Route exact path="/galery/:typeId/:catId/sc/:subCatId/:id" component={SingleArt} />
        <Route exact path="/galery/:typeId/:catId/sc/:subCatId" component={BySubCat} />
        <Route exact path="/galery/:typeId/:id" component={ByType} />
        <Route exact path="/galery/:typeId" component={ByType} />
        <Route exact path="/" component={HomePage} />
        <Route component={Error404}/>
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}
export default Router
