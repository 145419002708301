import React from 'react'
import styles from './Footer.module.css'
import logo from '../img/logo.png'

function Footer(props) {
  return (
    <div className={styles.footerStyle}>
      <div className={`${styles.textAlign} ${styles.infos}`}>
        <b>Isabelle Conrozier Kassovic</b>
        <a href="mailto:i.conrozier@orange.fr" className={styles.email}>i.conrozier@orange.fr</a>
        <p>06.84.98.80.78</p>
        <img className={styles.logo} src={logo} alt="logo" />
      </div>

      <div className={styles.textAlign}>
        <p className="footerText">
          Made with &hearts; by :
        </p>
      </div>

      <div
        lg={{size: '3', offset: '5'}}
        xs={{size: '9', offset: '1'}}
        className={`${styles.textAlign} ${styles.team}`}
      >
        <p className="footerText">Design :</p>
        <a href="mailto:jennifer.correiab@gmail.com" className="link">
          Jennifer Correia
        </a>
      </div>
      <div
        lg={{size: '3', offset: '5'}}
        xs={{size: '9', offset: '1'}}
        className={`${styles.textAlign} ${styles.team}`}
      >
        <p className="footerText">Dev : </p>
        <a
          href="https://github.com/christellec64"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Christelle Conrozier
        </a>
      </div>
    </div>
  )
}
export default Footer
