import Axios from 'axios'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem} from 'reactstrap'

import styles from './Navbar.module.css'
import logo from '../img/logo.png'

import {API_URL} from '../../ApiUrl.js'
import {Link} from 'react-router-dom'

function MyNavbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [type, setType] = useState([])
  const [errorType, setErrorType] = useState('')

  const toggle = () => setDropdownOpen(!dropdownOpen)

  const getType = async () => {
    try {
      const res = await Axios.get(`${API_URL}/api/type`)
      setType(res.data)
    } catch (err) {
      setErrorType(err)
    }
  }

  useEffect(() => {
    getType()
  }, [])

  return (
    <>
      {errorType ? (
        <p style={{color: 'red'}}>
          Une erreur est apparue lors du chargement des types d&apos;oeuvres
        </p>
      ) : (
        ''
      )}
      <Nav className={styles.navBar}>
        <NavItem className={styles.firstItem}>
          <Link to="/">
            <h1 className="logo">ICK</h1>
            <img className={styles.logo} src={logo} alt="logo" />
          </Link>
        </NavItem>
        <Dropdown
          nav
          isOpen={dropdownOpen}
          toggle={toggle}
          className={styles.secondItem}
        >
          <DropdownToggle nav caret>
            Galerie
          </DropdownToggle>
          <DropdownMenu className={styles.dropdownMenuStyle}>
            {type.map((item) => {
              return (
                <div key={item.id}>
                  <Link
                    to={`/galery/${item.id}`}
                    className={styles.dropdownMenuNavLink}
                  >
                    - {item.label}
                  </Link>
                </div>
              )
            })}
          </DropdownMenu>
        </Dropdown>
        <NavItem className={styles.navItem}>
          <Link to="/events" className={styles.secondItemLink}>
            Évènements
          </Link>
        </NavItem>

        <NavItem className={styles.navItem}>
          <Link to="/contact" className={styles.navItemLink}>
            Contact
          </Link>
        </NavItem>
      </Nav>
    </>
  )
}

export default MyNavbar
